// @ts-ignore
import NiceSelect from "nice-select2/src/js/nice-select2";
export default function bookingList() {

    const parentTargetNode = document.documentElement;
    const config = { childList: true, subtree: true };

    const selectBookingPeriod = document.querySelector(`#booking-date`) as HTMLSelectElement;
    selectBookingPeriod.addEventListener('change', () => {
        reloadPage()
    });

    let niceSelectBookingPeriod = new NiceSelect(selectBookingPeriod, {searchable: false});

    const ctaBookingFull = document.querySelector(`.card-booking__button`) as HTMLElement;
    function initBookingList() {
        // Reload js.
        const addBookingPopup = document.querySelector(`#add-booking-popup`) as HTMLElement;
        const blockCtaOpenPopup = document.querySelector(`.cta-add-booking`) as HTMLElement;
        const closePopup = addBookingPopup?.querySelector(`.add-booking-popup__close`) as HTMLSpanElement;
        const underlay = document.querySelector('#add-booking-popup .underlay') as HTMLElement;

        if (blockCtaOpenPopup) {
            blockCtaOpenPopup.addEventListener('click', () => {
                addBookingPopup.classList.add('active');
            });
        }

        if (closePopup) {
            closePopup.addEventListener('click', () => {
                addBookingPopup.classList.remove('active');
            });
        }

        if (underlay) {
            underlay.addEventListener('click', () => {
                if (addBookingPopup) {
                    addBookingPopup.classList.remove('active');
                    addBookingPopup.removeAttribute('style');
                }
            });
        }
    }

    setValueOnPageLoading();

    function reloadPage() {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('date')) {
            searchParams.set('date', selectBookingPeriod.value);
            window.location.search = searchParams.toString();
        } else {
            searchParams.append("date", selectBookingPeriod.value)
            window.location.search = searchParams.toString();
        }
    }

    function setValueOnPageLoading() {
        const params = new URLSearchParams(window.location.search);

        if (params.has('date')) {
            selectBookingPeriod.value = params.get('date') as string;
            niceSelectBookingPeriod.update();
        }

        const Links = document.querySelectorAll(`.nav-tabs__link`) as NodeListOf<HTMLLIElement>;

        if (params.has('type') && Links) {
            Links[0].classList.remove('current');

            const activeLink = document.querySelector(`.nav-tabs__link[data-param="${params.get('type')}"]`) as HTMLLIElement;
            activeLink?.classList.add('current');

            activeLink.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }

        // Set GET params from search filters on link to full booking. Allows user to keep search filters.
        // See bookingDetails.ts
        if (ctaBookingFull && (params.has('date') || params.has('type'))) {
            const href = ctaBookingFull.getAttribute('href');
            ctaBookingFull.setAttribute('href', href + '?' + params.toString());
        }

    }

    // Change when dom is inserted.
    // Fonction de rappel pour quand les mutations sont observées
    initBookingList();
    const callback = (mutationsList: MutationRecord[]) => {
        for(let mutation of mutationsList) {
            if (mutation.type === 'childList') {
                mutation.addedNodes.forEach((node) => {
                    const target = node as HTMLElement;
                    if (target.id == "block-nte-add-booking") {
                        initBookingList();
                    }
                });
            }
        }
    };
    const observer = new MutationObserver(callback);
    observer.observe(parentTargetNode, config);
}
