export let months = {
    1: "Janvier",
    2: "Février",
    3: "Mars",
    4: "Avril",
    5: "Mai",
    6: "Juin",
    7: "Juillet",
    8: "Août",
    9: "Septembre",
    10: "Octobre",
    11: "Novembre",
    12: "Décembre"
} as any;

export default async function calendar(action: any, fromOrTo: string | null, isBookingPanel: boolean = true) {
    const queryString = window.location.search;
    const langcode = window.location.pathname.split('/')[1];
    const urlParams = new URLSearchParams(queryString);
    const airport = urlParams.get('airport')
    const company = urlParams.get('company')
    const resetAirport = urlParams.get('reset')
    const allcompanies = urlParams.get('all')
    const selectAirport = document.querySelector('select[name="airport"]') as HTMLSelectElement;
    const selectCompany = document.querySelector('select[name="company"]') as HTMLSelectElement;
    let code_iata_arrival = null;
    let code_iata_departure = null;

    if (selectAirport) {
        selectAirport.onchange = (e: Event) => {
            const element = e.target as HTMLSelectElement;
            if (element.value.includes('airport')) {
                window.location.href = element.value
            }
            else {
                window.location.href = '?reset=airport'
            }
        };
    }

    // Change value of airport and company.
    if (selectAirport && airport) {
        const allSelectAirport = selectAirport.querySelectorAll('option');
        allSelectAirport.forEach((elem: HTMLOptionElement) => {
            elem.removeAttribute('selected');
            let airport_encode = encodeURIComponent(airport);
            if (elem.value.includes(airport_encode)) {
                elem.setAttribute('selected', '');
            }
        });
    }
    if (selectCompany && company) {
        const allSelectAirport = selectCompany.querySelectorAll('option');
        allSelectAirport.forEach((elem: HTMLOptionElement) => {
            elem.removeAttribute('selected');
            let company_encode = encodeURIComponent(company);
            if (elem.value.includes(company_encode)) {
                elem.setAttribute('selected', '');
            }
        });
    }

    // Scroll to calendar when there is are parameters in the url in destination page.
    if (airport || company || resetAirport || allcompanies) {
        setTimeout(() => {
            let destinationCalendar = document.querySelector(`.destination-full__content`) as HTMLElement;
            window.scrollTo({top: destinationCalendar.offsetTop - 200, behavior: 'smooth'});
        }, 800);
    }

    if (isBookingPanel) {
        const departureInput = document.querySelector('.form__cities input[name="departure"]') as HTMLInputElement;
        code_iata_departure = departureInput?.getAttribute('data-iata-id');

        const arrivalInput = document.querySelector('.form__cities input[name="arrival"]') as HTMLInputElement;
        code_iata_arrival = arrivalInput?.getAttribute('data-iata-id');
    } else {
        if (airport != null) {
            code_iata_arrival = airport;
        } else {
            // Ajax call to have any code iata (term id) of current destination.
            // For example, London have several airports.
            const destinationDiv = document.querySelector('.template-destination') as HTMLDivElement;
            const current_nid = destinationDiv?.getAttribute('data-node-id');

            if (typeof current_nid !== 'undefined') {
                const airports_request = `/${langcode}/ajax/airports/list/rest/${current_nid}`;
                let response = await fetch(airports_request);

                if (response.ok) {
                    let airports = await response.json();
                    if (airports) {
                        code_iata_arrival = airports.join(',');
                    }
                } else {
                    console.log("HTTP-Error: " + response.status);
                }
            }
        }

        // Code IATA Airport of Nantes.
        code_iata_departure = '6450';
    }

    // In booking page, arrival or departure could be empty. Need to set them to null
    if (code_iata_arrival == "") {
        code_iata_arrival = null;
    }
    if (code_iata_departure == "") {
        code_iata_departure = null;
    }

    let code_company = 'all';
    if (company != null) {
        code_company = company;
    }

    const url_ajax = `/${langcode}/ajax/flights/list/rest/${code_iata_departure}/${code_iata_arrival}/${code_company}`;
    let flightsSchedule = [] as any;

    // Call ajax.
    let response = await fetch(url_ajax);

    if (response.ok) {
        let flights = await response.json();
        if (flights) {
            flights.forEach(function (element: any) {
                flightsSchedule.push(element.field_takeoff_date);
            });
        }
    } else {
        console.log("HTTP-Error: " + response.status);
    }

    await initCalendar(flightsSchedule, action, fromOrTo);
}

export async function initCalendar(flightsSchedule: any, action: string | null, fromOrTo: string | null) {
    const calendarSelector = action == 'switch' ? '.booking-panel .calendar' : '.calendar' as string;
    const calendars = document.querySelectorAll(calendarSelector) as NodeListOf<Element>;

    calendars.forEach(function (calendar: any) {
        let today = new Date();

        // If a date selected.
        const fromInput = document.querySelector('.form__from-to input#from') as HTMLElement;
        let fromInputValue = fromInput?.getAttribute('data-value') as any;
        if (fromOrTo == 'from' && fromInputValue) {
            today = new Date(fromInputValue);
        } else if (fromOrTo == 'to') {
            const toInput = document.querySelector('.form__from-to input#to') as HTMLElement;
            let toInputValue = toInput.getAttribute('data-value') as any;

            if (toInputValue) {
                today = new Date(toInputValue);
            } else if (fromInputValue) {
                today = new Date(fromInputValue);
            }
        }

        let today_month = Number(today.getMonth() + 1);
        const date = {
            dayInt: today.getDate(),
            year: today.getFullYear(),
            month: today_month,
            today: today
        };

        // init calendar
        const calendarPages = calendar.querySelectorAll(".page");
        if (calendarPages) {
            calendarPages.forEach(function (element: HTMLElement, index: number) {
                if (calendarPages.length == 2 && index == 1) {
                    date.month = date.month + 1;
                }

                if (date.month == 13) {
                    date.month = 1;
                    date.year = date.year + 1;
                }
                showCalendar(date, element, flightsSchedule);
            });
        }

        // next and previous functionality
        let nextbtn = calendar.querySelector("button.next");
        let prevBtn = calendar.querySelector("button.prev");
        if (nextbtn) {
            nextbtn.onclick = function () {
                next(calendarPages, date, flightsSchedule);
            };
        }
        if (prevBtn) {
            prevBtn.onclick = function () {
                previous(calendarPages, date, flightsSchedule);
            };
        }

    });
}

// Event click day.
document.addEventListener('click', function (event: MouseEvent) {
    let elem = event.target as HTMLElement;
    if (!elem.closest('._days li')) return;

    // Active day in calendar.
    selectDay(elem);

    // Select day in booking panel.
    chooseDay(elem);
});

function chooseDay(elem: HTMLElement) {
    const destinationCalendar = document.querySelector('.destination-calendar') as HTMLElement;
    const bookingPanel = document.querySelector('.booking-panel') as HTMLElement;
    const fromCalendar = bookingPanel.querySelector('.from-to__calendar-from') as HTMLElement;
    const toCalendar = bookingPanel.querySelector('.from-to__calendar-to') as HTMLElement;
    const FromToInputFrom = bookingPanel.querySelector('.form__from-to input#from') as any;
    const FromToInputTo = bookingPanel.querySelector('.form__from-to input#to') as any;

    if (fromCalendar.contains(elem) || (destinationCalendar != undefined && destinationCalendar.contains(elem))) {
        // Reset the end date
        FromToInputTo.value = '';
        FromToInputTo.setAttribute("data-value", '');
        FromToInputFrom.value = getFormattedDateToDisplay(elem);
        FromToInputFrom.setAttribute("value", getFormattedDateToDisplay(elem));
        FromToInputFrom.setAttribute("data-value", getFormattedDate(elem));

    } else if (toCalendar.contains(elem)) {
        FromToInputTo.value = getFormattedDateToDisplay(elem);
        FromToInputTo.setAttribute("value", getFormattedDateToDisplay(elem));
        FromToInputTo.setAttribute("data-value", getFormattedDate(elem));
    }


}

/**
 * Simulate a click event.
 * @public
 * @param {Element} elem  the element to simulate a click on
 */
let simulateClick = function (elem: HTMLElement) {
    // Create our event (with options)
    let evt = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
    });
    // If cancelled, don't dispatch our event
    let canceled = !elem.dispatchEvent(evt);
};

function selectDay(elem: HTMLElement) {
    document.querySelectorAll("._days li").forEach((day: any) => {
        day.classList.remove("selected");
    })
    elem.classList.add("selected");

    // Event when click on dates.
    const parent = elem.closest('.calendar');
    const overlay = elem.closest('.overlay');
    if (parent && parent.classList.contains('from-to__calendar-from')) {
        const tabsBack = document.querySelector('.tabs-to') as HTMLElement;
        if (tabsBack && window.getComputedStyle(tabsBack).display !== 'none') {
            simulateClick(tabsBack);
        } else {
            if (overlay) {
                overlay.classList.remove('active');
            }
        }
    }
    if (parent && parent.classList.contains('from-to__calendar-to')) {
        if (overlay) {
            overlay.classList.remove('active');
        }
    }
}

function getFormattedDate(e: HTMLElement) {
    let showYear = e.getAttribute("data-year") as any;
    let showMonth = e.getAttribute("data-month") as any;
    let showDay = e.getAttribute("data-day") as any;

    return `${showYear}-${showMonth}-${showDay}`;
}

function getFormattedDateToDisplay(e: HTMLElement) {
    let showYear = e.getAttribute("data-year") as any;
    let showMonth = e.getAttribute("data-month") as any;
    let showDay = e.getAttribute("data-day") as any;

    let date = new Date(`${showYear}-${showMonth}-${showDay}`);

    // Define locale
    let locale = 'fr-FR';
    let pathArray = window.location.pathname.split('/');
    if (pathArray[1] == 'en') {
        locale = 'en-US';
    }

    const timeFormat = new Intl.DateTimeFormat(locale, {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
        year: '2-digit',
        hour12: false
    });

    return timeFormat.format(date);
}


export async function showCalendar(date: any, element: HTMLElement, flightsSchedule: any | null) {
    let calendarBody = element.querySelector("._days") as HTMLElement;

    const destinationCalendar = document.querySelector('.destination-calendar') as HTMLElement;
    const fromCalendar = document.querySelector('.from-to__calendar-from') as HTMLElement;
    const fromInput = document.querySelector('.form__from-to input#from') as HTMLElement;
    let fromInputValue = fromInput.getAttribute('data-value') as any;

    const toCalendar = document.querySelector('.from-to__calendar-to') as HTMLElement;
    const toInput = document.querySelector('.form__from-to input#to') as HTMLElement;
    let toInputValue = toInput.getAttribute('data-value') as any;

    // gets the day of the week for this date
    let firstDay = new Date(date.year, date.month - 1).getDay();
    // clearing all previous cells
    if (calendarBody) {
        calendarBody.innerHTML = "";
    }
    // checking the mount of days in this month to control the loop
    let totalDays = daysInMonth(date.month, date.year);

    // adding the blank boxes so that date start on correct day of the week
    // substracting 1 to set monday as the first weekday
    // and testing for sunday because it became the 7th day
    blankDates(firstDay === 0 ? 6 : firstDay - 1, calendarBody);

    // adding the dates to the calendar
    for (let day = 1; day <= totalDays; day++) {
        // create li node with text content & apend to body
        let cell = document.createElement("li");
        let cellText = document.createTextNode(day.toString());
        let dayString = day.toString().padStart(2, '0');
        let monthString = date.month.toString().padStart(2, '0');

        // appending date attributes to single date li element
        cell.setAttribute("data-day", dayString);
        cell.setAttribute("data-month", monthString);
        cell.setAttribute("data-year", date.year);

        let theday = new Date(`${date.year}-${date.month}-${day}`) as Date;
        let today = new Date() as Date;
        let startday = new Date(today.setDate(today.getDate() + 1)) as Date;

        let thisDay = `${date.year}-${monthString}-${dayString}`;

        // Past days
        if (theday.getTime() < startday.getTime()) {
            cell.classList.add("past-day");
        }

        // Check for retour.
        if (element.parentElement && element.parentElement.classList.contains('from-to__calendar-to')) {
            // Past days for "to" calendar, before start date.
            if (theday < new Date(fromInputValue)) {
                cell.classList.add("past-day");
            }
        }

        // Day with a flight.
        if (flightsSchedule.includes(thisDay)) {
            cell.classList.add("hasVol");
        }

        // Insert selected day on From or destination calendar.
        if (fromInputValue && (destinationCalendar != undefined && destinationCalendar.contains(element) || fromCalendar.contains(element))) {
            if (thisDay == fromInputValue) {
                cell.classList.add("selected");
            }
        }

        // Insert selected days "To" Calendar
        if (toCalendar.contains(element)) {
            if (thisDay == fromInputValue || thisDay == toInputValue) {
                cell.classList.add("selected");
            }
        }

        // adding active class if day matches today
        if (date.dayInt === day &&
            date.month === today.getMonth() + 1 &&
            date.year === today.getFullYear()) {
            cell.classList.add("current-day");
        }


        //appending li to body of calendar
        cell.classList.add("singleDay");
        cell.appendChild(cellText);

        if (calendarBody) {
            calendarBody.appendChild(cell);
        }
    }

    // set month string value
    let currentMonth = element.querySelector("._month");
    if (currentMonth) {
        currentMonth.innerHTML = months[date.month] as any;
    }
    // set year string value
    let currentYear = element.querySelector("._year");
    if (currentYear) {
        currentYear.innerHTML = date.year;
    }
}

function daysInMonth(month: any, year: any) {
    // day 0 here returns the last day of the PREVIOUS month
    return new Date(year, month, 0).getDate();
}

function blankDates(count: any, calendarBody: HTMLElement | null) {
    // looping to add the correct amount of blank days to the calendar
    for (let x = 0; x < count; x++) {
        let cell = document.createElement("li");
        let cellText = document.createTextNode("");
        cell.appendChild(cellText);
        // add the empty class to remove the borders
        cell.classList.add("empty");
        if (calendarBody) {
            calendarBody.appendChild(cell);
        }
    }
}

function next(calendarPages: any, date: any, flightsSchedule: any) {
    if (calendarPages) {
        calendarPages.forEach(function (element: HTMLElement, index: number) {
            if (calendarPages.length == 1) {
                date.month = date.month + 1;
            } else {
                date.month = date.month + index;
            }

            if (date.month == 13) {
                date.month = 1;
                date.year = date.year + 1;
            }

            showCalendar(date, element, flightsSchedule);
        });
    }
}

function previous(calendarPages: any, date: any, flightsSchedule: any) {
    if (calendarPages) {
        calendarPages.forEach(function (element: HTMLElement, index: number) {
            if (calendarPages.length == 1) {
                date.month = date.month - 1;
            } else if (index == 0) {
                date.month = date.month - 2;
            } else if (index == 1) {
                date.month = date.month + 1;
            }

            if (date.month == 13) {
                date.month = 1;
                date.year = date.year + 1;
            }

            if (date.month == 0) {
                date.month = 12;
                date.year = date.year - 1;
            }

            if (date.month == -1) {
                date.month = 11;
                date.year = date.year - 1;
            }

            showCalendar(date, element, flightsSchedule);
        });
    }
}
